<template>
  <div>
    <v-form ref="member_form">
      <v-row class="mb-4">
        <v-col>
          <h2 v-if="isAddMember">Add Member</h2>
          <h2 v-else>Edit Member</h2>
        </v-col>
      </v-row>
      <v-card elevation="0" class="px-6 py-4 mb-8">
        <v-row>
          <v-col>Register date: {{ member_data.create_date }}</v-col>
          <v-col>
            <div class="d-flex justify-end">
              <div>Active Member</div>
              <div>
                <v-switch
                color="#0d6efd"
                value
                input-value="true"
                height="0"
                v-model="member_data.active"
                ></v-switch>
            </div>
          </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="12" sm="12" col="12">
            <v-text-field
              type="text"
              label="User ID"
              outlined
              hide-details="auto"
              v-model="member_data.username"
              :rules="[Validator.required]"
              class="pa-0 ma-0"
            >
            </v-text-field>
          </v-col>
          <v-col>
                        <v-select
              :items="user_type"
              v-model="member_data.user_type"
              label="User Type"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col col="12" class="xs-full">
            <v-text-field
              type="text"
              :rules="[Validator.required, Validator.isNumberOnly]"
              outlined
              v-model="member_data.phone"
              label="Phone"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col col="12">
            <v-text-field
              type="text"
              :rules="[Validator.required, Validator.isEmail]"
              outlined
              v-model="member_data.email"
              label="E-mail"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="12" col="12" class="xs-full">
            <v-text-field
              type="text"
              :rules="[Validator.isNumberOnly]"
              v-model="member_data.credit"
              label="Credits"
              hide-details="auto"
              outlined
              readonly
              >
            </v-text-field>
          </v-col>
          <v-col xl="3" lg="3" md="3" sm="12" col="12" class="xs-full">
            <v-btn color="success" :loading="submitLoading" @click="addCreditData()" width="100%" height="56px!important">
              <v-icon class="mr-1">
                {{ icons.mdiPlusCircle }}
              </v-icon>
              ADD CREDIT
            </v-btn>
          </v-col>
          <v-col xl="3" lg="3" md="3" sm="12" col="12" class="xs-full">
            <v-btn color="accent" :loading="submitLoading" @click="deductCreditData()" width="100%" height="56px!important">
              <v-icon class="mr-1">
                {{ icons.mdiMinusCircle }}
              </v-icon>
              DEDUCT CREDIT
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn color="success" :loading="submitLoading" @click="saveMemberData()">儲存</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-form>

    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row>
        <v-col>
          <h3>Record</h3>
        </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col>
          <v-tabs v-model="tab">
            <v-tab key="event">Event Purchase</v-tab>
            <v-tab key="store">Store Purchase</v-tab>
            <v-tab key="redeem">Redeem</v-tab>
            <v-tab key="credit">Credit</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item key="event">
              <data-table-with-pagination
                :headers="ticket_header"
                :dataList="ticket_list"
              ></data-table-with-pagination>
            </v-tab-item>
            <v-tab-item key="store">
              <data-table-with-pagination
                :headers="order_header"
                :dataList="order_list"
              ></data-table-with-pagination>
            </v-tab-item>
            <v-tab-item key="redeem">
              <data-table-with-pagination
                :headers="redeem_header"
                :dataList="redeem_list"
              ></data-table-with-pagination>
            </v-tab-item>
            <v-tab-item key="credit">
              <data-table-with-pagination
                :headers="credit_header"
                :dataList="credit_list"
              ></data-table-with-pagination>
            </v-tab-item>
          </v-tabs-items>
          
        </v-col>
      </v-row>
    </v-card>
    
    <LoadingDialog :load="showLoadingPage"></LoadingDialog>
    <AlertBox :type="alertBox.type" :text="alertBox.text" :show="alertBox.show"></AlertBox>
  </div>
</template>

<script>
import { Validator } from '@/global'
import LoadingDialog from '@/components/LoadingDialog'
import AlertBox from '@/components/AlertBox'
import DataTableWithPagination from '@/components/DataTableWithPagination.vue'
import { globalFunc } from '@/global'
import { mapActions } from 'vuex'
import { getData, postData } from '@/api'
import { mdiPlusCircle } from '@mdi/js';
import { mdiMinusCircle } from '@mdi/js';

export default {
  name: 'member-management-detail',
  components: {
    LoadingDialog,
    AlertBox,
    DataTableWithPagination,
  },
  data: () => ({
    user_center_id: -1,
    verifiedError: false,
    isAddMember: false,
    globalFunc: globalFunc,
    showLoadingPage: false,
    submitLoading: false,
    tab: null,
    icons: {
      mdiPlusCircle, mdiMinusCircle
    },
    alertBox: {
      show: false,
      text: '',
      type: '',
    },
    user_type: [
      "Normal","VIP","VVIP"
    ],
    ticket_header: [
      { text: 'Date', value: 'date' },
      { text: 'Tickets Number', value: 'ticket_id' },
      { text: 'Order Number', value: 'order_id' },
      { text: 'Status', value: 'status' },
    ],
    ticket_list: [
      { date: '2021-01-13', ticket_id: 'TONE006', order_id: 'E000016', status: 'Used' },
      { date: '2021-01-12', ticket_id: 'TONE005', order_id: 'E000016', status: 'Available' },
    ],
    credit_header: [
      { text: 'Date', value: 'date' },
      { text: 'Amount', value: 'credit_amount' },
      { text: 'Type', value: 'credit_type' }
    ],
    credit_list: [],
    redeem_header: [
      { text: 'Date', value: 'date' },
      { text: 'Product', value: 'product' },
      { text: 'Status', value: 'status' }
    ],
    redeem_list: [],
    order_header: [
      { text: 'Date', value: 'date' },
      { text: 'Order Number', value: 'id' },
      { text: 'Tickets Number', value: 'ticket_id' },
      { text: 'Amount', value: 'amount' },
      { text: 'Status', value: 'status' }
    ],
    order_list: [],
    record_data: [],
    load_record_table: true,
    register_detail_data: [],
    Validator: Validator,
    member_data: {
      username: '',
      user_type: '',
      old_password: '',
      password: '',
      permission_type: '',
      position: '',
      center_id: '',
      status: '',
    },
    backup_member_data: {},
    user_type_list: [
      {
        'item-text': '用戶',
        'item-value': 'user',
      },
      {
        'item-text': '員工',
        'item-value': 'staff',
      },
    ],
    permission_type_list: [
      {
        'item-text': '普通',
        'item-value': 'normal',
      },
      {
        'item-text': '管理員',
        'item-value': 'super_admin',
      },
    ],
    status_list: [
      { 'item-text': '已激活', 'item-value': 'active' },
      { 'item-text': '未激活', 'item-value': 'inactive' },
    ],
    center_list: [],
    center_rules: [],
    center_readOnly: true,
    error: {
      password: false,
    },
  }),
  methods: {
    ...mapActions(['reset']),
    /**
    * Initializing the data
    */
    async initializeData() {
      try {
        let params = new URLSearchParams()
        params.set(
          'get_user_by_id',
          JSON.stringify({
            id: this.$route.params.id,
          }),
        )

        let params2 = new URLSearchParams()
        params2.set(
          'get_all_credit',
          JSON.stringify({
            id: this.$route.params.id,
          }),
        )

        let result = await getData(params)
        let data = result
        let credit_data = await this.getCreditData()
        console.log('--- Get member data ---')
        console.log(result)

        let result_credit = await getData(params2)
        for(let i=0; i<result_credit.length;i++) {
          this.credit_list.push({ date: globalFunc.getDateStr(result_credit[i].create_date), credit_amount: result_credit[i].amount, credit_type:result_credit[i].type})
        }

        let temp_data = {
          create_date: globalFunc.getDateStr(data.create_date),
          active: data.active,
          username: data.username,
          email: data.email,
          phone: data.phone,
          credit: credit_data,
        }

        Object.assign(this.member_data, temp_data)
        Object.assign(this.backup_member_data, temp_data)

      } catch (error) {
        console.log('--- Get member data fail ---')
        console.log(error)
      }
    },
    /**
     * Getting the credit from user id
    */
    async getCreditData() {
        let params = new URLSearchParams()
        params.set(
          'get_credit_of_user',
          JSON.stringify({
            id: this.$route.params.id,
          }),
        )
        let result = await getData(params)
        return result;
    },
    /**
     * Making a prompt to add credit
    */
    async addCreditData() {
      let send_data = {}
      let input = parseInt(prompt("Please input a number","0"))
      if (!input) { return true }
      if (Number.isInteger(input)) {
          send_data.amount = input
          send_data.user_id = parseInt(this.$route.params.id)
          let params = new URLSearchParams()
          params.set('add_credit', JSON.stringify(send_data))
          let result = await getData(params)
          this.getCreditData()
          this.member_data.credit+=result.amount;
          console.log(result)

          if (!Array.isArray(result.data)) {
            this.alertBox.text = '儲存成功'
            this.alertBox.type = 'success'
            this.alertBox.show = true
          } else {
            this.alertBox.text = '沒有資料需要更新'
            this.alertBox.type = 'error'
            this.alertBox.show = true
          }
        } else {
          alert("Please Enter a Number")
        }
    },
    /**
     * making a prompt to deduct credit
    */
    async deductCreditData() {
      let send_data = {}
      let input = parseInt(prompt("Please input a number","0"))
      if (!input) { return true }
      if (Number.isInteger(input)) {
          send_data.amount = input
          send_data.user_id = parseInt(this.$route.params.id)
          let params = new URLSearchParams()
          params.set('use_credit', JSON.stringify(send_data))
          let result = await getData(params)
          this.getCreditData()
          this.member_data.credit-=result.amount;
          console.log(result)

          if (!Array.isArray(result.data)) {
            this.alertBox.text = '儲存成功'
            this.alertBox.type = 'success'
            this.alertBox.show = true
          } else {
            this.alertBox.text = '沒有資料需要更新'
            this.alertBox.type = 'error'
            this.alertBox.show = true
          }
        } else {
          alert("Please Enter a Number")
        }
    },
    /**
     * Saving the member data to backend
    */
    async saveMemberData() {
      try {
        let key = ''
        let send_data = {}
    
        if (!this.isAddMember) {
          send_data.id = parseInt(this.$route.params.id)
          // send_data.editor = parseInt(this.$route.params.id)

          let updated = false
          if (this.member_data.username != this.backup_member_data.username) {
            updated = true
            send_data.username = this.member_data.username
          }

          if (this.member_data.active != this.backup_member_data.active) {
            updated = true
            send_data.active = this.member_data.active
          }

          if (this.member_data.phone != this.backup_member_data.phone) {
            updated = true
            send_data.phone = this.member_data.phone
          }

          if (this.member_data.email != this.backup_member_data.email) {
            updated = true
            send_data.email = this.member_data.email
          }

          if (this.member_data.user_type != this.backup_member_data.user_type) {
            updated = true
            // send_data.user_type = this.member_data.user_type
          }

          console.log(send_data)
          
          if (!updated) {
            this.alertBox.text = '沒有資料需要更新'
            this.alertBox.type = 'error'
            this.alertBox.show = true
            return
          }

          key = 'update_user'
        } else {
          // send_data.editor = parseInt(this.$store.state.api.login_data.id)
          send_data.id = parseInt(this.$route.params.id)
          send_data.user_type = this.member_data.user_type
          send_data.username = this.member_data.username
          send_data.phone = this.member_data.phone
          send_data.email = this.member_data.email
          // send_data.user_type = this.member_data.user_type
          send_data.active = this.member_data.active
          key = 'register'

          console.log(send_data)
        }

        let params = new URLSearchParams()
        params.set(key, JSON.stringify(send_data))
        let result = await getData(params)
        console.log(result)


        if (result) {
          this.alertBox.text = '儲存成功'
          this.alertBox.type = 'success'
          this.alertBox.show = true
          setTimeout(() => {
            this.$router.replace({ name: 'member-management' })
          }, 1500)
        } else {
          this.alertBox.text = '沒有資料需要更新'
          this.alertBox.type = 'error'
          this.alertBox.show = true
        }
      } catch (error) {
        console.log(error)
        this.alertBox.text = '儲存失敗'
        this.alertBox.type = 'error'
        this.alertBox.show = true
      } finally {
        setTimeout(() => {
          this.submitLoading = false
          this.alertBox.show = false
        }, 1500)
      }
    },

    /**
     * kicking out the user
    */
    kickout() {
      console.log('--- Validation Fail ---')
      this.alertBox.text = '登入憑證過期，3秒後重新導向'
      this.alertBox.type = 'error'
      this.alertBox.show = true

      setTimeout(() => {
        this.alertBox.show = false
        let send_data = []
        send_data['method'] = 'resetData'
        this.reset(send_data)
        this.$router.push({ name: 'Login' })
      }, 3000)
    },
  },
  /**
   * Initializing at the beginning
  */
  created() {
    setTimeout(async () => {
      console.log('--- Validation ---')
      if (globalFunc.DataValid(this.$store.state.api.login_data)) {
        try {
          this.isAddMember = true
          this.showLoadingPage = true
          setTimeout(() => {
            this.showLoadingPage = false
          }, 1000)

          if (globalFunc.DataValid(this.$route.params.id)) {
            this.isAddMember = false
            this.initializeData()
          }
        } catch (error) {
          console.log('--- Check User Permission Fail ---')
          console.log(error)
          this.verifiedError = true
          this.showLoadingPage = false
          this.alertBox.text = '資料取得失敗'
          this.alertBox.type = 'error'
          this.alertBox.show = true

          setTimeout(() => {
            this.alertBox.show = false
            this.$router.replace({ name: 'member-management' })
          }, 3000)
        }
      } else {
        this.kickout()
      }
    }, 200)
  },
  watch: {
    /*     'member_data.user_type': function (newVal) {
      if (this.isAddMember) {
        if (this.center_readOnly) {
          this.center_readOnly = false
          this.center_rules = [Validator.required]
        }
      }
    }, */
  },
}
</script>
